import React from 'react';

export const SendIcon = (
  { fill, ...props }, //eslint-disable-line
) => (
  <svg
    width="19px"
    height="19px"
    viewBox="0 0 19 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.09350977,15.7529801 L8.71829858,2.46297554 C9.04094873,1.84533809 9.94520685,1.84576683 10.2684554,2.46371051 L16.9061194,15.7600032 C17.2293679,16.3779468 16.6699398,17.1019876 15.9593791,16.952529 L10.5,16 L9.5,8 L8.5,16 L3.0414262,16.9890104 C2.33098923,17.0951891 1.77088025,16.4132238 2.09350977,15.7529801 Z"
      id="icon"
      fill={fill}
      fillRule="nonzero"
      transform="translate(9.500000, 9.500000) rotate(90.000000) translate(-9.500000, -9.500000) "
    />
  </svg>
);
