import PropTypes from 'prop-types';
import React from 'react';
import Page from '../page';
import forDevice from '../../hoc/for-device';
import classNames from 'classnames';
import styles from './post-page-layout.scss';

const PostPageLayoutMobile = ({ children, ...otherProps }) => (
  <Page noSpacingTop {...otherProps}>
    {children}
  </Page>
);

PostPageLayoutMobile.propTypes = {
  children: PropTypes.node,
};

const PostPageLayoutDesktop = ({ children, className, ...otherProps }) => {
  return (
    <Page className={classNames(styles.container, className)} {...otherProps}>
      {children}
    </Page>
  );
};

PostPageLayoutDesktop.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default forDevice(PostPageLayoutMobile, PostPageLayoutDesktop);
