import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { HorizontalSeparator } from '../separator';
import CategoryListItem from './category-list-item-mobile';
import SubcategoryListItem from './subcategory-list-item-mobile';
import { getSubcategories } from '../../../common/selectors/categories-selectors';
import { getIsShowSubcategoriesEnabled } from '../../selectors/app-settings-selectors';
import styles from './category-list-classic-mobile.scss';

const ListItemFragment = ({
  category,
  subcategories,
  isShowSubcategoriesEnabled,
  isViewsCountEnabled,
}) => (
  <div
    className={classNames(
      'forum-card-border-color',
      'forum-card-background-color',
      'forum-text-color',
    )}
  >
    <CategoryListItem category={category} isViewsCountEnabled={isViewsCountEnabled} />
    {isShowSubcategoriesEnabled &&
      subcategories.length > 0 &&
      subcategories.map((subcategory, idx) => (
        <React.Fragment key={subcategory._id}>
          <HorizontalSeparator className={idx === 0 ? styles.separator : styles.separatorShort} />
          <SubcategoryListItem category={subcategory} isViewsCountEnabled={isViewsCountEnabled} />
        </React.Fragment>
      ))}
  </div>
);

const mapRuntimeToProps = (state, { category }, actions, host) => ({
  subcategories: getSubcategories(state, category._id),
  isShowSubcategoriesEnabled: getIsShowSubcategoriesEnabled(state, host.style),
});

const ListItem = flowRight(connect(mapRuntimeToProps))(ListItemFragment);

const CategoryListClassicMobile = ({ categories, isViewsCountEnabled }) => (
  <div>
    {categories.map((category, idx) => (
      <React.Fragment key={category._id}>
        {idx > 0 && <HorizontalSeparator />}
        <ListItem category={category} isViewsCountEnabled={isViewsCountEnabled} />
      </React.Fragment>
    ))}
  </div>
);

CategoryListClassicMobile.propTypes = {
  categories: PropTypes.array.isRequired,
  isViewsCountEnabled: PropTypes.bool,
};

export default CategoryListClassicMobile;
