import React from 'react';

const SearchIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="16"
    viewBox="0 0 15 16"
    style={{ fillRule: 'evenodd' }}
    {...props}
  >
    <path
      d="M878.766,254.573l-3.7-3.96a6.555,6.555,0,0,0,1.471-4.153,6.274,6.274,0,1,0-6.27,6.46,6.074,6.074,0,0,0,3.592-1.169l3.725,3.99a0.8,0.8,0,0,0,1.156.024A0.862,0.862,0,0,0,878.766,254.573Zm-8.5-12.888a4.777,4.777,0,1,1-4.636,4.775A4.712,4.712,0,0,1,870.27,241.685Z"
      transform="translate(-864 -240)"
    />
  </svg>
);

export default SearchIcon;
