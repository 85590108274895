import PropTypes from 'prop-types';
import React from 'react';

export const ViewCountIcon = ({ title, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="12"
    viewBox="0 0 19 12"
    style={{
      fillRule: 'evenodd',
    }}
    role="img"
    {...props}
  >
    <title>{title}</title>
    <path
      d="M680.408,226c-3.162,0-6.216-1.708-9.079-5.079h0a1.464,1.464,0,0,1,0-1.889c1.259-1.51,4.674-5.024,9.188-5.045h0.04c3.162,0,6.216,1.708,9.079,5.079a1.465,1.465,0,0,1,0,1.889c-1.259,1.51-4.674,5.024-9.188,5.045h-0.039Zm-8.293-5.729c2.659,3.131,5.449,4.717,8.293,4.717h0.035c4.079-.018,7.233-3.276,8.4-4.675a0.463,0.463,0,0,0,.008-0.6c-2.659-3.131-5.448-4.718-8.293-4.718h-0.034c-4.079.019-7.234,3.277-8.4,4.676a0.464,0.464,0,0,0-.008.6h0Zm8.368,3.62a3.9,3.9,0,1,1,3.952-3.9A3.93,3.93,0,0,1,680.483,223.891Zm0-6.783a2.886,2.886,0,1,0,2.926,2.886A2.909,2.909,0,0,0,680.483,217.108Z"
      transform="translate(-671 -214)"
    />
  </svg>
);

ViewCountIcon.propTypes = {
  title: PropTypes.string,
};
