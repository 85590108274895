import React from 'react';

export const SortIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" {...props}>
    <defs>
      <path
        id="sort-a"
        d="M9.8298342,9.00144036 L18.8055823,9.00144036 C18.9160393,9.00144036 19.0055823,9.09098341 19.0055823,9.20144036 L19.0055823,9.80109863 C19.0055823,9.91155558 18.9160393,10.0010986 18.8055823,10.0010986 L9.85383616,10.0010986 L12.7618741,12.9091366 C12.8405717,12.9878342 12.8410522,13.1149476 12.7629473,13.1930525 L12.3386832,13.6173166 C12.2605784,13.6954214 12.1334649,13.6949409 12.0547674,13.6162434 L8.06492124,9.62639728 C8.02557247,9.58704851 8.00577797,9.53559576 8.00558377,9.48421709 C8.00538957,9.43283843 8.02479566,9.38153385 8.06384809,9.34248142 L12.0236461,5.38268344 C12.1017509,5.30457859 12.2288644,5.30505905 12.3075619,5.38375659 L12.7350454,5.81124011 C12.813743,5.88993765 12.8142234,6.01705111 12.7361186,6.09515597 L9.8298342,9.00144036 Z"
      />
    </defs>
    <g fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M1.82425188,9.00144036 L10.8,9.00144036 C10.9104569,9.00144036 11,9.09098341 11,9.20144036 L11,9.80109863 C11,9.91155558 10.9104569,10.0010986 10.8,10.0010986 L1.84825383,10.0010986 L4.75629183,12.9091366 C4.83498937,12.9878342 4.83546983,13.1149476 4.75736497,13.1930525 L4.33310091,13.6173166 C4.25499605,13.6954214 4.12788259,13.6949409 4.04918504,13.6162434 L0.0593389173,9.62639728 C0.0199901464,9.58704851 0.000195644766,9.53559576 1.4431211e-06,9.48421709 C-0.000192758523,9.43283843 0.0192133398,9.38153385 0.058265769,9.34248142 L4.01806374,5.38268344 C4.0961686,5.30457859 4.22328206,5.30505905 4.3019796,5.38375659 L4.72946312,5.81124011 C4.80816066,5.88993765 4.80864112,6.01705111 4.73053627,6.09515597 L1.82425188,9.00144036 Z"
        transform="rotate(90 5.5 9.5)"
      />
      <use fillRule="nonzero" transform="scale(1 -1) rotate(90 23.006 0)" xlinkHref="#sort-a" />
    </g>
  </svg>
);
