import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './post-hashtags.scss';
import Hashtags from '../hashtags';

const PostHashtags = ({ type, hashtags }) => {
  const className = classNames(styles.container, styles[type]);

  return (
    <div className={className} data-hook="post-hashtags">
      <Hashtags hashtags={hashtags} />
    </div>
  );
};

PostHashtags.SMALL = 'small';
PostHashtags.MEDIUM = 'medium';
PostHashtags.LARGE = 'large';
PostHashtags.SEARCH = 'search';
PostHashtags.MEMBERS_AREA = 'membersArea';
PostHashtags.MOBILE = 'mobile';

PostHashtags.propTypes = {
  type: PropTypes.oneOf([
    PostHashtags.SMALL,
    PostHashtags.MEDIUM,
    PostHashtags.LARGE,
    PostHashtags.SEARCH,
    PostHashtags.MOBILE,
  ]).isRequired,
  hashtags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PostHashtags;
