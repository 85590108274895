import PostListItem from '../post-list-item';

export const mapTypeToSize = size => {
  switch (size) {
    case PostListItem.SMALL:
      return {
        width: 300,
      };
    case PostListItem.MEDIUM:
      return {
        width: 458,
      };
    case PostListItem.LARGE:
      return {
        width: 938,
      };
    default:
      return {
        width: 600,
      };
  }
};
