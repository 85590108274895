import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import FluidAvatarImage from '../fluid-avatar-image';
import styles from './header-avatar-image.scss';

const HeaderAvatarImage = ({ user }) => (
  <div className={classNames(styles.container, 'header-avatar-image')}>
    <div className={styles.image}>
      <FluidAvatarImage image={user.image} />
    </div>
  </div>
);

HeaderAvatarImage.propTypes = {
  user: PropTypes.object.isRequired,
};

export default HeaderAvatarImage;
