import PropTypes from 'prop-types';
import React from 'react';
import { Img } from '@wix/communities-image-lib';
import styles from './post-image.scss';
import { mapTypeToSize } from './utils';

const PostImage = ({ image, url, isPublic, type }) => {
  const props = isPublic ? { 'data-pin-url': url } : { 'data-pin-nopin': true };
  return (
    <div className={styles.container}>
      <Img className={styles.image} src={image} {...mapTypeToSize(type)} {...props} />
    </div>
  );
};

PostImage.propTypes = {
  image: PropTypes.object.isRequired,
  url: PropTypes.string,
  isPublic: PropTypes.bool,
  type: PropTypes.string,
};

export default PostImage;
