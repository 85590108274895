import React from 'react';
import forDirection from '../../hoc/for-direction';

export const MoreIconCenter = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 19 19" role="img" {...props}>
    <path d="M9.44398805,5.99973295 C8.62345525,5.9690612 7.98007565,5.28418875 8.00047182,4.46312144 C8.02086799,3.64205413 8.69745853,2.98998831 9.51850166,3.0001164 C10.3395448,3.01024449 10.9998531,3.67880182 11,4.50012255 C10.9842481,5.34399206 10.287639,6.0153508 9.44398805,5.99973295 Z M9.44398805,10.9997329 C8.62345525,10.9690612 7.98007565,10.2841888 8.00047182,9.46312144 C8.02086799,8.64205413 8.69745853,7.98998831 9.51850166,8.0001164 C10.3395448,8.01024449 10.9998531,8.67880182 11,9.50012255 C10.9842481,10.3439921 10.287639,11.0153508 9.44398805,10.9997329 Z M9.44398805,15.9997329 C8.62345525,15.9690612 7.98007565,15.2841888 8.00047182,14.4631214 C8.02086799,13.6420541 8.69745853,12.9899883 9.51850166,13.0001164 C10.3395448,13.0102445 10.9998531,13.6788018 11,14.5001225 C10.9842481,15.3439921 10.287639,16.0153508 9.44398805,15.9997329 Z" />
  </svg>
);

export const MoreIconCenterRtl = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 19 19" role="img" {...props}>
    <path d="M9.44398805,5.99973295 C8.62345525,5.9690612 7.98007565,5.28418875 8.00047182,4.46312144 C8.02086799,3.64205413 8.69745853,2.98998831 9.51850166,3.0001164 C10.3395448,3.01024449 10.9998531,3.67880182 11,4.50012255 C10.9842481,5.34399206 10.287639,6.0153508 9.44398805,5.99973295 Z M9.44398805,10.9997329 C8.62345525,10.9690612 7.98007565,10.2841888 8.00047182,9.46312144 C8.02086799,8.64205413 8.69745853,7.98998831 9.51850166,8.0001164 C10.3395448,8.01024449 10.9998531,8.67880182 11,9.50012255 C10.9842481,10.3439921 10.287639,11.0153508 9.44398805,10.9997329 Z M9.44398805,15.9997329 C8.62345525,15.9690612 7.98007565,15.2841888 8.00047182,14.4631214 C8.02086799,13.6420541 8.69745853,12.9899883 9.51850166,13.0001164 C10.3395448,13.0102445 10.9998531,13.6788018 11,14.5001225 C10.9842481,15.3439921 10.287639,16.0153508 9.44398805,15.9997329 Z" />
  </svg>
);

export default forDirection(MoreIconCenter, MoreIconCenterRtl);
