import PropTypes from 'prop-types';
import React from 'react';
import styles from './post-image.scss';
import { mapTypeToSize } from './utils';

const PostGif = ({ gif, type, ...props }) => {
  return (
    <div className={styles.container}>
      <img
        className={styles.image}
        src={gif.originalUrl}
        alt="gif used in post"
        {...mapTypeToSize(type)}
        {...props}
      />
    </div>
  );
};

PostGif.propTypes = {
  gif: PropTypes.object.isRequired,
  type: PropTypes.string,
};

export default PostGif;
