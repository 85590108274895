import React from 'react';
import Header from '../../components/header';
import HeaderButtons from '../../components/header-buttons';
import BackButton from '../../components/header-back-button';
import PropTypes from 'prop-types';

const InternalPageHeader = ({ params, ...props }) => (
  <Header left={<BackButton />} right={<HeaderButtons params={params} />} {...props} />
);

InternalPageHeader.propTypes = {
  params: PropTypes.object.isRequired,
};

export default InternalPageHeader;
