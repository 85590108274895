import React from 'react';
import PropTypes from 'prop-types';
import styles from './shadow-container.scss';

const ShadowContainer = ({ children }) => <div className={styles.container}>{children}</div>;

ShadowContainer.propTypes = {
  children: PropTypes.any,
};

export default ShadowContainer;
