import PropTypes from 'prop-types';
import React from 'react';
import VideoEmbed from '../video-embed';
import styles from './post-video-embed.scss';

const PostImage = ({ oembed }) => (
  <div className={styles.container}>
    <VideoEmbed oembed={oembed} />
  </div>
);

PostImage.propTypes = {
  oembed: PropTypes.object.isRequired,
};

export default PostImage;
